<template>
  <div>
    <h4>Gráficas de atencíon por tipo de solicitud</h4>
    
    <div class="form-row grupo">
      <div class="form-group col-md-4">
        <label>Elija el tipo de solicitud</label>
        <select
          class="form-control"
          style="max-width: 500px"
          v-model="busqueda.id_tipo"
        >
          <option value="" selected>Todas</option>
          <option
            v-for="tipo in tipos"
            :key="tipo.id_tiposolicitud"
            v-bind:value="tipo.id_tiposolicitud"
          >
            {{ tipo.tiposolicitud }}
          </option>
        </select>
      </div>
      <div class="form-group col-md-4">
        <label>Fecha inicial</label>
        <b-form-datepicker
          v-model="busqueda.fechai"
          placeholder="Todas"
        ></b-form-datepicker>
      </div>
      <div class="form-group col-md-4">
        <label>Fecha final</label>
        <b-form-datepicker
          v-model="busqueda.fechaf"
          placeholder="Todas"
        ></b-form-datepicker>
      </div>
      <div class="form-group col-md-12 text-right">
        <button class="btn btn-primary" v-on:click.stop.prevent="buscar">
          Buscar
        </button>
      </div>
    </div>

    <div v-for="(dato, indice) in datos" :key="indice">
      <bar-chart :chart-data="dato" :width="80" :height="280" />
    </div>
  </div>
</template>

<script>
import api from "@/api";
import BarChart from "../components/BarChart";

export default {
  name: "GAtencionArea",
  components: { BarChart },
  data() {
    return {
      tipos: [],
      datos: [],
      busqueda: {
        id_tipo: '',
        fechai: null,
        fechaf: null,
      },
    };
  },
  methods: {
    dividir(obj, size) {
      let result = [];
      for (let i=0; i<obj.labels.length; i+=size) {
        let r = {
          datasets: [
          {
            backgroundColor: obj.datasets[0].backgroundColor,
            borderColor: obj.datasets[0].borderColor,
            label: obj.datasets[0].label,
            data: obj.datasets[0].data.slice(i,i+size),
          },
          {
            backgroundColor: obj.datasets[1].backgroundColor,
            borderColor: obj.datasets[1].borderColor,
            label: obj.datasets[1].label,
            data: obj.datasets[1].data.slice(i,i+size),
          },
          {
            backgroundColor: obj.datasets[2].backgroundColor,
            borderColor: obj.datasets[2].borderColor,
            label: obj.datasets[2].label,
            data: obj.datasets[2].data.slice(i,i+size),
          }],
          labels: obj.labels.slice(i,i+size),
        }
        result.push(r);
      }
      return result;
    },
    buscar() {
      api
        .graficaTipoSolicitud(this.busqueda)
        .then((respuesta) => {
          this.datos = this.dividir(respuesta.data, 12);
      });
    },
  },
  mounted() {
    api
      .listarTipoSolicitud()
      .then((respuesta) => (this.tipos = respuesta.data));
    this.buscar();
  },
};
</script>


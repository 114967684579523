<template>
  <div>
    <div style="position: relative">
      <span v-if="contenido == null" style="color: red">
        <font-awesome-icon icon="exclamation-circle" />
        Sin archivo
      </span>
      <span v-if="contenido != null">
        <a href="javascript:;" v-on:click="abrirArchivo">
          <font-awesome-icon icon="file-pdf" />
          Documento.pdf
        </a>
      </span>
      <button class="btn btn-secondary" v-on:click="subirArchivo">
        <font-awesome-icon icon="file-pdf" />
      </button>
      <button v-if="false" class="btn btn-secondary" v-on:click="quitarArchivo">
        <font-awesome-icon icon="trash" />
      </button>

      <div style="position: absolute; top: 0; right: 0">
        <div style="height: 5px"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { API_URL } from "@/api";
export default {
  name: "ArchivoPdf",
  props: {
    value: {},
  },
  data() {
    return {
      API_URL: API_URL,
      inputfile: null,
      contenido: this.value,
    };
  },
  methods: {
    onItemSelected(item) {
      this.selectedItem = item;
    },
    subirArchivo: function () {
      this.inputfile.onchange = (e) => {
        let archivo = e.target.files[0];
        //alert(archivo.type);
        if (archivo.type != "application/pdf") {
          alert("El archivo debe ser de tipo pdf");
          return;
        }
        if (archivo.size > 1024 * 1024 * 10) {
          alert("Solo se permiten archivos de hasta 10 MB");
          return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(archivo, "UTF-8");
        reader.onload = (readerEvent) => {
          this.contenido = readerEvent.target.result;
        };
      };

      this.inputfile.click();
    },
    abrirArchivo: function () {
      if (this.contenido.endsWith(".pdf")) {
        window.open(`${API_URL}/archivos/${this.contenido}`, "_blank");
      } else {
        const win = window.open("", "_blank");
        let html = "";

        html += '<html><body style="margin:0!important">';
        html +=
          '<embed width="100%" height="100%" src="' +
          this.contenido +
          '" type="application/pdf" />';
        html += "</body></html>";

        setTimeout(() => {
          win.document.write(html);
        }, 0);
      }
    },
    quitarArchivo: function () {
      this.contenido = null;
    },

    pdf: function () {
      if (this.contenido != null && this.contenido.length > 120) {
        let pos = this.contenido.indexOf("base64,");
        let img = this.contenido;
        if (pos > 0) {
          img = img.substr(pos + 7);
        }
        return img;
      }
      return null;
    },
  },
  watch: {
    contenido() {
      this.$emit("input", this.contenido);
    },
    value(valor) {
      this.contenido = valor;
    },
  },
  created() {
    this.inputfile = document.createElement("input");
    this.inputfile.type = "file";
    this.inputfile.accept = "application/pdf";
  },
};
</script>

<style scoped>
button {
  background: lightsteelblue;
}
</style>